<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-col cols="12" md="4" lg="2" class="pa-0">
          <label>Company</label>
          <v-autocomplete
            v-model="accountUuid"
            :items="companyList"
            :loading="loading"
            outlined
            item-text="accountName"
            item-value="accountUuid"
            solo
            class="muted--text"
            light
            color="muted"
            label="Company"
          />
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="paginationList"
          :loading="loading"
          :items-per-page="itemsPerPage"
          item-key="id"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="{
                'success-lighten': item.paid,
                'red-lighten': !item.paid
              }"
              small
              class="text-capitalize"
            >
            <span v-if="item.paid">Paid</span>
            <span v-else>Unpaid</span>
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="viewInvoice(item)"
                  >
                    <v-icon color="#919EAB">payment</v-icon>
                  </v-btn>
                </template>
                <span>View Invoice</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-divider/>
        <div v-if="countPage > 1" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex flex-wrap h-max">
            <template v-for="index in countPage">
              <v-divider
                v-if="index"
                :key="index + 'v-divider--page'"
                vertical
                class="pa-0"
              />
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex" style="min-width: 73px;">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <vue-snotify/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Invoice',
  data: () => ({
    loading: false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: 'Created On',
        align: 'start',
        sortable: false,
        value: 'created',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Company Name',
        align: 'start',
        sortable: false,
        value: 'account_name',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Invoice #',
        align: 'start',
        sortable: false,
        value: 'id',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Amount',
        align: 'start',
        sortable: false,
        value: 'total',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    total: 0,
    companyList: [],
    accountUuid: ''
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.itemsPerPage)
    },
    paginationList () {
      return this.items.slice((this.page - 1) * this.itemsPerPage, this.itemsPerPage * this.page)
    }
  },
  watch: {
    accountUuid () {
      this.page = 1
      this.loadInvoices()
    }
  },
  created () {
    if (this.$route.params.companyUuid) this.accountUuid = this.$route.params.companyUuid
    this.loadInvoices()
    this.loadCompany()
  },
  methods: {
    ...mapActions('admin', ['getAdminInvoices', 'getAdminCompany']),
    async loadInvoices () {
      try {
        this.loading = true
        const params = {
          limit: 100,
          orderType: 'desc'
        }
        if (this.accountUuid) params.account_uuid = this.accountUuid
        const { data } = await this.getAdminInvoices(params)
        if (!data) return
        this.items = data.data.map(item => ({
          ...item,
          created: new Date(item.created * 1000)
        }))
        this.total = data.total
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async loadCompany () {
      try {
        const params = {
          limit: 9999
        }
        const { data } = await this.getAdminCompany(params)
        if (!data) return
        const companyList = data.data
          .filter(e => !!e.accountName && !!e.accountName.trim())
          .map(e => ({
            ...e,
            accountName: e.accountName.slice(0, 25)
          }))
          .sort((a, b) => (a.accountName.toLowerCase() < b.accountName.toLowerCase() ? -1
            : a.accountName.toLowerCase() > b.accountName.toLowerCase() ? 1 : 0))
        this.companyList = [
          {
            accountName: 'Not selected',
            accountUuid: ''
          },
          ...companyList
        ]
      } catch (err) {
        throw new Error(err)
      }
    },
    viewInvoice (item) {},
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>
