<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" class="">
        <v-row class="ma-0 gap-10">
          <!-- <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Company</label>
            <v-autocomplete
              v-model="accountUuid"
              :items="companyList"
              :loading="loading"
              outlined
              item-text="accountName"
              item-value="accountUuid"
              solo
              class="muted--text"
              light
              color="muted"
              label="Company"
            />
          </v-col> -->
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Company Name</label>
            <v-text-field
              v-model="filter.account_name"
              outlined
              solo
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Phone number</label>
            <v-text-field
              v-model="filter.filterByNumber"
              outlined
              solo
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Status</label>
            <v-select
              v-model="filter.status"
              :items="statusOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              solo
              outlined
              hide-details
              class="pa-0"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="primary mr-3 text-none"
          text
          @click="assignDialog = true"
        >
        <v-icon size="17px">add</v-icon>
          Assign DID
        </v-btn>
        <v-btn
          class="px-0 grey-button"
          outlined
          @click="exportCurrentView"
        >
          Export
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="itemsPerPage"
          item-key="id"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="{
                'success-lighten': item.status,
                'red-lighten': !item.status
              }"
              small
              class="text-capitalize"
            >
            <span v-if="item.status">Active</span>
            <span v-else>Inactive</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-divider/>
        <div v-if="total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex flex-wrap h-max">
            <template v-for="index in countPage">
              <v-divider
                v-if="index"
                :key="index + 'v-divider--page'"
                vertical
                class="pa-0"
              />
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex" style="min-width: 73px;">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="assignDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '600' : '100%'"
    >
      <v-card>
        <v-card-title class="font-weight-bold"> Assign DID </v-card-title>

        <v-row class="ma-0 gap-10">
          <v-col cols="12" class="d-flex flex-column flex-md-row align-md-center justify-space-between py-0">
            <label>Company</label>
            <v-autocomplete
              v-model="accountUuid"
              :items="companyList"
              :loading="loading"
              :menu-props="{
                contentClass: 'did-company-select'
              }"
              outlined
              item-text="accountName"
              item-value="accountUuid"
              solo
              class="muted--text"
              light
              color="muted"
              label="Company"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-column flex-md-row align-md-center justify-space-between py-0">
            <label>DID Number</label>
            <v-text-field
              v-model="didNumber"
              :maxlength="11"
              outlined
              solo
              class="muted--text"
              light
              color="muted"
              label="Enter DID number"
              @keypress="IsNumber"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-column flex-md-row align-md-center justify-space-between py-0">
            <label>Expiration Date</label>
            <date-picker-solo
              :value="expireOn"
              enable-future
              inner-class="date-picker"
              @changeDate="changeDate"
            />
          </v-col>
        </v-row>

        <v-card-actions class="pb-5 pt-5 justify-md-end">

          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'" class="mx-2">
            <v-btn
              color="secondary"
              outlined
              class="w-100 text-capitalize"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
          </div>

          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'" class="mx-2">
            <v-btn
              :disabled="disableSubmit"
              depressed
              color="primary"
              @click="assignDid"
              class="w-100 text-capitalize"
            >
              Assign DID
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-snotify/>
  </div>
</template>

<script>
import DatePickerSolo from '@/components/DatePickerSolo'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import exportToCsv from '@/utilities/exportCSV'

export default {
  name: 'Number',
  components: {
    DatePickerSolo
  },
  data: () => ({
    loading: false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: 'Created On',
        align: 'start',
        sortable: false,
        value: 'created_on',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Company Name',
        align: 'start',
        sortable: false,
        value: 'acco_name',
        class: ['text-caption', 'greyBuoyant'],
        formatter: (x) => x.slice(0, 25),
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Released On',
        align: 'start',
        sortable: false,
        value: 'released_on',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Phone Number',
        align: 'start',
        sortable: false,
        value: 'did_number',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Expired On',
        align: 'start',
        sortable: false,
        value: 'expire_on',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    total: 0,
    companyList: [],
    assignDialog: false,
    accountUuid: '',
    didNumber: '',
    expireOn: '',
    filter: {
      account_name: '',
      filterByNumber: '',
      account_uuid: '',
      status: ''
    },
    statusOptions: [
      {
        label: 'Not selected',
        value: ''
      },
      {
        label: 'Active',
        value: 'true'
      },
      {
        label: 'Inactive',
        value: 'false'
      }
    ]
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.itemsPerPage)
    },
    disableSubmit () {
      return !this.accountUuid || this.didNumber.length < 11 || this.didNumber[0] !== '1' || !this.expireOn
    }
  },
  watch: {
    page () {
      this.loadDIDs()
    },
    /* accountUuid () {
      this.page = 1
      this.loadDIDs()
    }, */
    filter: {
      deep: true,
      handler () {
        this.page = 1
        this.loadDIDs()
      }
    }
  },
  created () {
    if (this.$route.params.companyUuid) this.filter.account_uuid = this.$route.params.companyUuid
    this.loadDIDs()
    this.loadCompany()
  },
  methods: {
    ...mapActions('admin', ['getAdminDIDs', 'getAdminCompany', 'adminAssignDID']),
    async loadDIDs (forExport = false) {
      try {
        this.loading = true
        const params = {
          offset: forExport ? 0 : (this.page - 1) * this.itemsPerPage,
          limit: forExport ? 9999 : this.itemsPerPage,
          orderType: 'desc',
          order_by: 'created_on'
        }
        Object.entries(this.filter).forEach(([key, value]) => {
          if (value) params[key] = value
        })
        const { data } = await this.getAdminDIDs(params)
        if (!data) return
        if (forExport) return data.data
        this.items = data.data
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async loadCompany () {
      try {
        const params = {
          limit: 9999
        }
        const { data } = await this.getAdminCompany(params)
        if (!data) return
        const companyList = data.data.filter(e => !!e.accountName && !!e.accountName.trim())
        this.companyList = [
          {
            accountName: 'Not selected',
            accountUuid: ''
          },
          ...companyList
        ]
      } catch (err) {
        throw new Error(err)
      }
    },
    async exportCurrentView () {
      const csvArray = []
      let values = []
      const self = this

      this.headers.forEach(function (item, i) {
        if (item.value !== 'action') {
          values.push(item.text)
        }
      })
      csvArray.push(values)
      const items = await this.loadDIDs(true)
      items.forEach(function (item, i) {
        values = []
        self.headers.forEach(function (it, i) {
          if (it.value !== 'action') {
            if (Object.keys(it).includes('formatter')) {
              const val = self.formatDate(item[it.value])
              values.push(val)
            } else {
              values.push(item[it.value])
            }
          }
        })
        csvArray.push(values)
      })

      exportToCsv('Numbers.csv', csvArray)
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    },
    viewInvoice (item) {},
    async assignDid () {
      if (this.disableSubmit) return
      this.$snotify.notifications = []
      try {
        const payload = {
          did: this.didNumber,
          expire_on: this.expireOn
        }
        const { data } = await this.adminAssignDID({ accountUuid: this.accountUuid, payload })
        if (!data) return
        this.$snotify.success('DID number successfully assigned')
        this.closeDialog()
      } catch (err) {
        const message = err.message || err
        this.$snotify.error(message)
        throw new Error(err)
      }
    },
    closeDialog () {
      this.assignDialog = false
      this.accountUuid = ''
      this.didNumber = ''
      this.expireOn = ''
    },
    changeDate (e) {
      this.expireOn = e
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  width: 176px;
  margin-bottom: 10px;
}
::v-deep .v-text-field__details {
  display: none;
}
@media (min-width: 960px) {
  ::v-deep .date-picker {
    max-width: 400px;
  }
}
</style>
